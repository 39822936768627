<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC2QMW3K_6nmjZW3Iw5i_XR6NVA22HCCoY&libraries=places"></script>

<div #viewportChecker>
  <div [ngClass]="appClass" class="">
    <!-- BEGIN: Header-->
    <nav
      class="header-navbar navbar navbar-expand-lg align-items-center fixed-top navbar-light navbar-shadow"
    >
      <div class="navbar-container d-flex content">
        <div class="brand-image">
          <img
            src="../../../../assets/img/site-icon.svg"
            class="pointer"
            width="40px"
            alt="BizPay"
            (click)="toggleSideMenu()"
          />
        </div>
        <span class="ml-auto"></span>
        <ul class="nav navbar-nav align-items-center ml-auto">
          <li *ngIf="!expired">
            <div class="d-flex">
              <i
                class="pi pi-bell notification-bell p-text-secondary"
                pBadge
                [value]="notificationCount.toString()"
                (click)="rightBarHandler(rightBarRole.Notification)"
              ></i>

              <p-divider layout="vertical" styleClass="h-100"></p-divider>
            </div>
          </li>
          <li class="nav-item dropdown dropdown-user" *ngIf="loggedInUser">
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              href="javascript:void(0);"
              (click)="rightBarHandler(rightBarRole.Profile)"
            >
              <span class="avatar">
                <span class="avatar-content">{{
                  loggedInUser | initalspipe
                }}</span>
              </span>

              <div class="user-nav d-sm-flex d-none ms-2">
                <span class="user-name fw-bolder"
                  >{{ loggedInUser.first_name }}
                  {{ loggedInUser.last_name }}</span
                ><span class="user-status">{{ currentRole }}</span>
              </div>
            </a>
          </li>
          <li>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-angle-down"
              class="p-button-rounded p-button-secondary p-button-text"
              (click)="rightBarHandler(rightBarRole.Setting)"
            ></button>
          </li>
        </ul>
      </div>
    </nav>

    <!-- END: Header-->

    <!-- BEGIN: Main Menu-->

    <app-side-bar
      (sideBarEvent)="sideBarEventHandler($event)"
      (userSettingEvent)="handleUserSetting($event)"
      [mainSideBarStatus]="sideBarStatus"
      [hide_menu]="expired"
    ></app-side-bar>
    <!-- END: Main Menu-->

    <!-- BEGIN: Content-->

    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div
          style="position: fixed; z-index: 99; right: 80px"
          [style.top.px]="viewHeight - 60 "
        >

          <p-speedDial
          direction="up-left"
          radius="100"
          type="quarter-circle"
            [model]="leftTooltipItems"
            [mask]="true"
            showIcon="pi pi-question-circle"
            hideIcon="pi pi-times"
            hideOnClickOutside=true
            (onShow)="toggleMaskData(1)"
            (onHide)="toggleMaskData(0)"
          />
        </div>
        <p-messages severity="success" *ngIf="version_update">
          <ng-template pTemplate>
            <div class="ml-2">
              New updates are available! Clear your cache now to access the
              latest updates. We appreciate your patience during this
              improvement phase.
            </div>
            <div class="ml-auto">
              <p-button label="Reload" (click)="reloadApp()"></p-button>
            </div>
          </ng-template>
        </p-messages>
        <p-messages severity="success" *ngIf="showKYC">
          <ng-template pTemplate>
            <div class="ml-2" *ngIf="loggedInUser.kyc_status=='I'">
              The KYC process has been initiated. If already completed KYC<span
                (click)="checkUserKycStatus()"
                class="text-bold pointer"
              >
                click here</span
              >
              to check status.
            </div>
            <div class="ml-2" *ngIf="loggedInUser.kyc_status=='P'">
              A kit has been assigned to you. Please complete your KYC to activate your wallet.
            </div>
            <div class="ml-auto">
              <a routerLink="/user/kyc">Complete KYC</a>
            </div>
          </ng-template>
        </p-messages>
        <p-messages
          severity="error"
          *ngIf="plan_expiring && !expired && !renewal_mode"
        >
          <ng-template
            pTemplate
            *ngIf="loggedInUser.organization.subscription_type_id == 1"
          >
            <div class="ml-2">
              Your free trial period will expire in {{ no_of_day }} days, to
              continue enjoying features for bizpay, activate elite plan !
            </div>
            <span class="ml-auto"></span>
            <button
              pButton
              pRipple
              type="button"
              label="Upgrade"
              class="p-button-raised p-button-danger"
              (click)="toggelPlan()"
            ></button>
          </ng-template>
          <ng-template
            pTemplate
            *ngIf="loggedInUser.organization.subscription_type_id != 1"
          >
            <div class="ml-2">
              Your subscription will expire in {{ no_of_day }} days, Renew now
              to keep enjoying uninterrupted access!
            </div>
            <span class="ml-auto"></span>
            <button
              pButton
              pRipple
              type="button"
              label="Renew"
              class="p-button-raised p-button-danger"
              (click)="toggelPlan()"
            ></button>
          </ng-template>
        </p-messages>

        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- END: Content-->
  </div>
  <p-sidebar
    [(visible)]="rightBar"
    position="right"
    [baseZIndex]="10000"
    [styleClass]="menuRoleName == rightBarRole.Notification ? 'w-30' : ''"
  >
    <app-profile
      *ngIf="menuRoleName != rightBarRole.Notification"
    ></app-profile>
    <!-- USER PROFILE MENU START -->
    <app-profile-menu
      [day_remaining]="no_of_day"
      *ngIf="menuRoleName == rightBarRole.Profile"
      (closeSideBarEvent)="handleSideBarEvent($event)"
      (subscriptionModeEvent)="handleSubcriptionEvent($event)"
    ></app-profile-menu>
    <!-- USER PROFILE MENU END -->

    <!-- USER SETTING MENU START -->
    <app-setting-menu
      (closeSideBarEvent)="handleSideBarEvent($event)"
      *ngIf="menuRoleName == rightBarRole.Setting"
    ></app-setting-menu>
    <!-- USER SETTING MENU END -->

    <!-- USER NOTIFICATION START -->
    <app-notification
      *ngIf="menuRoleName == rightBarRole.Notification"
      (isRead)="handleMarkNotification()"
      [userNotification]="notification"
    ></app-notification>
    <!-- USER NOTIFICATION END -->
  </p-sidebar>
</div>

<!-- PLAN START -->
<p-dialog
  header="Subscription Plans"
  [(visible)]="displayPlan"
  [modal]="true"
  [style]="{ width: '70vw' }"
  [draggable]="false"
  [resizable]="false"
  position="top"
>
  <app-plan-offered (close_plan)="toggelPlan()"></app-plan-offered>
</p-dialog>
<!-- PLAN END -->
<!-- REMINDER START -->
<p-dialog
  [(visible)]="displayReminder"
  [showHeader]="false"
  [modal]="true"
  [style]="{ width: '1180px', height: '100vh' }"
  contentStyleClass="p-0"
>
  <app-free-trial-reminder
    *ngIf="displayReminder"
    [item]="reminder_type"
    [height]="viewHeight"
    (upgradeType)="handleUpgradeType($event)"
    (close)="closeReminder()"
  >
  </app-free-trial-reminder>
</p-dialog>
<!-- REMINDER END -->
<!-- KYC POPUP -->
<p-dialog
  [(visible)]="showKYCNotice"
  [showHeader]="false"
  [modal]="true"
  [style]="{ width: '30%' }"
>
  <div class="text-center p-5" *ngIf="KYCNoticeType == 0">
    <img
      src="../../../../assets/img/in_progress.svg"
      alt="Not Found"
      class="mb-5"
    />
    <h4>KYC in Progress</h4>
    <p>
      Once your KYC is completed, please allow 5-6 working days for the process
      to finalize
      <br />
      <br />
      For any issues, please contact
    </p>
    <a href="mailto:care@bizpay.co.in">care&#64;bizpay.co.in</a>
  </div>
  <div class="text-center p-5" *ngIf="KYCNoticeType == 1">
    <img
      src="../../../../assets/img/congratulation.svg"
      alt="congratulation"
      class="mb-5"
    />
    <h4>Congratulations!!</h4>
    <p>
      Your KYC process is complete! You now have access to all wallet
      functionalities
      <br />
      <br />
    </p>
  </div>
</p-dialog>
<!-- KYC POPUP ENDS -->

<!-- PIN SET START -->
<p-dialog
  [showHeader]="false"
  [(visible)]="set_pin"
  [modal]="true"
  [style]="{ width: '100vw', height: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <app-manage-pin (pinStatus)="handlePinStatus($event)"></app-manage-pin>
</p-dialog>
<!-- PIN SET END -->


<!-- Expense Classification START -->
<p-dialog
  [showHeader]="false"
  [(visible)]="show_classification"
  [modal]="true"
  position="top"
  [style]="{ width: '707px',height:'627.94px' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  styleClass="br-4"
>
  <app-expense-classification [expenseList]="classification_list" (closeClasify)="handleClassifyModal($event)" ></app-expense-classification>
</p-dialog>
<!-- Expense Classification END -->
