//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//#endregion Imports

//#region Internal Imports
import { HttpProvider } from 'app/core/common/http-provider.service';
import { User } from 'app/core/model/users/user.modal';

//#endregion Internal Imports

@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpProvider {
  //#region Variable
  private tagEndpoint = '/user/';
  private readonly userListEndpoint = this.tagEndpoint;
  private readonly userDataEndpoint = this.tagEndpoint;
  private readonly userAddOrEditEndpoint = this.tagEndpoint;
  private readonly organizationProgressEndPoint = '/organization_progress/';
  private readonly userKYCdataEndPoint = '/kyc/update_user/';
  private readonly userListKYCdataEndPoint = '/kyc/update_user_list/';
  private readonly KYCLinkEndPoint = '/kyc/generate_vcip_link/';
  private readonly KYCGetLinkEndPoint = '/kyc/get_vcip_link/';
  private readonly toggleOnBoardEndPoint = '/on_boarded_status/';
  private readonly changePasswordEndPoint = '/change_password/';
  private readonly updateKYCEndpoint = '/update_kyc_status/';
  private readonly reTriggerEndpoint = '/re_trigger_password_mail/';
  private readonly importUserEndpoint = '/user_import/';
  private readonly userlistByIdEndpoint = '/user_list/';
  private readonly walletDataEndpoint = '/bank/user_kit/';
  private readonly approverEndpoint = '/approver_dropdown/';
  private readonly verifierEndpoint = '/verifer_dropdown/';
  private readonly usermetaEndpoint = '/user_meta/';
  private readonly assignkitEndpoint = '/assign_kit/';
  //------------------\\
  userId: number = 0;
  userData: User = new User();
  //#endregion
  //#region Constructor
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }
  //#endregion

  //#region GET
  getUserList(role?: string) {
    if (role == undefined || role == '') {
      return this.get(this.userListEndpoint);
    } else {
      const queryParams = {
        role_id: role,
      };
      return this.get(this.userListEndpoint, true, {
        params: this.toHttpParams(queryParams),
      });
    }
  }

  getUserData(id: any) {
    return this.get(this.userDataEndpoint + `${id}/`);
  }

  setUserObject(user: User) {
    this.userData = user;
  }

  getUserObject() {
    return this.userData;
  }
  //#endregion

  //#region POST
  addOrEditUser(data) {
    if (data.id != null && data.id != 0) {
      return this.put(this.userAddOrEditEndpoint + data.id + '/', data);
    } else {
      return this.post(this.userAddOrEditEndpoint, data);
    }
  }

  //#endregion

  //#region Delete
  deleteUser(id) {
    return this.delete(this.userDataEndpoint + `${id}/`);
  }

  //#endregion

  //#region
  getOrganizationProgress() {
    return this.get(this.organizationProgressEndPoint);
  }

  updateOrganizationProgress(data) {
    return this.put(this.organizationProgressEndPoint, data);
  }

  toggleOnBoardingProcess(id, status) {
    return this.put(this.toggleOnBoardEndPoint + `${id}/`, status);
  }

  updateKycData(value: any) {
    return this.put(this.userKYCdataEndPoint + `${value.id}/`, value);
  }

  updateUserListForKYC(data: any) {
    return this.post(this.userListKYCdataEndPoint, data);
  }

  getKycLinkForUser() {
    return this.post(this.KYCLinkEndPoint, null);
  }
  updayeKycStatus(id, value) {
    return this.put('/user/' + `${id}/` + this.updateKYCEndpoint, value);
  }

  getNewKYCLink(data) {
    return this.post(this.KYCGetLinkEndPoint, data);
  }

  changePassword(form: any) {
    return this.put(this.changePasswordEndPoint, form);
  }

  reTriggerMail(data: any) {
    return this.post(this.reTriggerEndpoint, data);
  }

  importUser(fileData: any) {
    return this.post(this.importUserEndpoint, fileData);
  }

  getUserListById(data: any) {
    return this.post(this.userlistByIdEndpoint, data);
  }

  getAssignedWallet(id: number) {
    const queryParams = {
      organization_id: id,
    };
    return this.get(this.walletDataEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getApproverDropdown(id) {
    const queryParams = {
      organization_id: id,
    };
    return this.get(this.approverEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getVerifierDropdown(id) {
    const queryParams = {
      organization_id: id,
    };
    return this.get(this.verifierEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  //#endregion

  //#region  Getter n Setter

  public getUserId() {
    return this.userId;
  }

  public setUserId(id: number) {
    this.userId = id;
  }

  getUsersExcel(){
    const query_params = {
      export_type:'excel'
    }
    return this.getBlob(this.usermetaEndpoint, true, query_params)
  }

  assignWallet(data){
    return this.post(this.assignkitEndpoint, data)
  }
  //#endregion
}
