//We Can Authorise Other routes Also 
export const RouteConstantAdmin ={
    route:{
        AdminDashboard: '/dashboard/admin-dashboard',
        Wallets:'/transcation/card',
        Settelements:'/settlement',
        Reports:'/expense/report',
        Expenses:'/expense/expense',
        Teams:'/dashboard/teams',
        MoneyRequests:'/transcation/money-request-dashboard',
        Approvals:'/expense/approval',
        Settings:'/user/setting',
        OrganisationSettings:'/administrator/',
        OrganizationDetails:'/administrator/organization',
        Integrations:'/administrator/integration',
        Expense:'/expense/expense-type',
        ExpenseBucket:'/expense/expense-bucket',
        UserSettings:'/user',
        Users:'user/user-list',
        Roles:'/administrator/role',
        Policies:'/administrator/policy',
        Onboarding:'/user/onboard',
        ControlPanel:'/admin-panel',
        OrganizationList:'/admin-panel/organization-list',
        ExpenseForm:'/expense/expense-form',
        ReportDashboard:'/expense/report-dashboard/'
        //#Add More routes if Required
      }
}
export const RouteConstantFinance={
    route:{
        FinanceDashboard:'/dashboard/finance-dashboard',
        Wallets:'/transaction/card',
        Reports:'/expense/reports',
        Expenses:'/expense/expense',
        Teams:'/dashboard/team',
        MoneyRequests:'/transcation/money-request-dashboard',
        Approvals:'/user/approvals',
        ExpenseForm:'/expense/expense-form',
        ReportDashboard:'/expense/report-dashboard/'
        //#Add more routes if Required
    }
}
export const RouteConstantManager={
    route:{
    Teams:'/dashboard/team',
    Expense:'/expense/expense-bucket-dashboard',
    
    //#Add more routes if Required
    }
}
export const RouteConstantUsers={
    route:{
    UserDashboard:'/dashboard/user-dashboard',
    Reports:'/expense/report',
    MyWallets:'/transaction/wallet',
    MoneyRequests:'/transcation/money-request-dashboard',
    Approvals:'/expense/approvals',
    Settings:'/user/setting',
    Expenses:'/expense/expense',
    ExpenseForm:'/expense/expense-form',
    ReportDashboard:'/expense/report-dashboard/'
    
    //#Add more routes if Required
    }
}